import React from 'react'

import Layout from '../components/layout'
import Map from '../components/map'

const IndexPage = props => (
  <Layout pageTitle="Current Vehicle Location">
    <Map uuid={props.location.search} />
  </Layout>
)

export default IndexPage
