import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import target from '../images/group-3.png'

const LocationCard = styled.div`
  min-width: 346px;
  width: 100%;
  height: 55px;
  border: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0.575rem;
`

const Target = styled.img`
  margin: 0;
  display: inline-block;
  margin-right: 8px;
`

const LocationText = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: ${props => (props.alertColor ? 'black' : 'red')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const LocationStatus = styled.p`
  margin: 0;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.38);
`

const TextContainer = styled.div`
  display: inline-block;
  width: 90%;
`

const AddressBox = ({ placeholder, alertColor, text }) => (
  <LocationCard placeholder={placeholder || 'Vehicle Location'}>
    <Target src={target} height="23px" width="23px" alt="bullseye" />
    <TextContainer>
      <LocationText alertColor={alertColor}>{text}</LocationText>
      <LocationStatus>
        {!alertColor
          ? 'Current Vehicle Location Unavailable'
          : 'Current Vehicle Location'}
      </LocationStatus>
    </TextContainer>
  </LocationCard>
)

AddressBox.propTypes = {
  placeholder: PropTypes.string,
  text: PropTypes.string.isRequired,
  alertColor: PropTypes.bool,
}

export default AddressBox
