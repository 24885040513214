export const getResultByTypeAndDistanceToCoords = (originatingCoords, geocodingResult) => {
    const filteredResults = geocodingResult.filter(result => {
        return result.types.includes("route")
            || result.types.includes("establishment")
            || result.types.includes("street_address")
            || result.types.includes("premise")
    })

    if (filteredResults.length === 0) {
        return geocodingResult[0]
    }

    let distanceToResults
    try {
        distanceToResults = filteredResults
            .map(result => {
                result.distanceFromOrigin = window.google.maps.geometry.spherical.computeDistanceBetween(originatingCoords, result.geometry.location)
                return result
            })
            .sort((a, b) => a.distanceFromOrigin - b.distanceFromOrigin)
    } catch (e) {
        return geocodingResult[0]
    }

    return distanceToResults[0]
}

export const formatAddress = (addressInfo) => {
        const formattedAddress = {};
        const addressDetails = parseAddressComponents(addressInfo.address_components)
        formattedAddress.street_number = addressDetails.get("street_number")?.short_name;
        formattedAddress.street_name = addressDetails.get("route")?.short_name;
        formattedAddress.city = addressDetails.get("locality,political")?.short_name ||
            addressDetails.get("locality")?.short_name ||
            addressDetails.get("administrative_area_level_3,political")?.short_name ||
            addressDetails.get("sublocality_level_1,sublocality,political")?.short_name ||
            addressDetails.get("neighborhood")?.short_name;
        formattedAddress.county = addressDetails.get("administrative_area_level_2,political")?.short_name;
        formattedAddress.state = addressDetails.get("administrative_area_level_1,political")?.short_name;
        formattedAddress.long_state = addressDetails.get("administrative_area_level_1,political")?.long_name;
        formattedAddress.zipcode = addressDetails.get("postal_code")?.short_name;
        formattedAddress.country = addressDetails.get("country,political")?.short_name;

        const {lat, lng} = getLatLngAsValue(addressInfo.geometry.location)
        formattedAddress.latitude = lat;
        formattedAddress.longitude = lng;
        formattedAddress.formatted_address = addressInfo.formatted_address
        formattedAddress.accuracy = getAccuracyFromLocationType(addressInfo.geometry?.location_type)
        return formattedAddress
}

const parseAddressComponents = (addressComponents) => {

    const addressComponentMap = new Map()
    addressComponents.forEach(component => {
        addressComponentMap.set(component.types.join(), {short_name: component.short_name, long_name: component.long_name})
    })

    return addressComponentMap
}

export const getLatLngAsValue = (location) => {
    let {lat, lng} = location
    if(lat instanceof Function){
        lat = lat()
        lng = lng()
    }

    return {lat, lng}
}

const getAccuracyFromLocationType = (locationType) => {
    switch (locationType) {
        case "ROOFTOP":
            return 1
        case "RANGE_INTERPOLATED":
            return 2
        case "GEOMETRIC_CENTER":
            return 3
        case "APPROXIMATE":
            return 4
    }

}
