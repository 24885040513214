import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import axios from 'axios'
import styled from '@emotion/styled'

import AddressBox from './addressBox'
import markerIcon from '../images/current-location-pin.svg'
import createMapOptions from '../utils/mapOptions'
import {
  getLocationEndpoint,
} from '../utils/constants/endpoints'
import { registerEvent } from '../utils/analytics'
import {getResultByTypeAndDistanceToCoords, formatAddress} from '../utils/mapUtils'

const MapWrapper = styled.div`
  height: ${props => (props.calculatedWidth ? '100%' : '99.9%')};
  justify-content: space-around;
  width: ${props => (props.calculatedWidth ? '100%' : '99.9%')};
`

const GoogleMap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
`

const Overlay = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  width: 95%;
  flex-direction: column;
  bottom: 3%;
`

const Submit = styled(Link)`
  display: flex;
  height: 50px;
  border: 2px solid #black;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background-color: #ff9e16;
  width: 100%;
  border-radius: 8px;
  color: #001e60;
  align-items: center;
  font-size: 1.15rem;
  font-weight: 500;
  justify-content: center;
  text-decoration: none;
  margin-top: 10px;
`

const DisabledSubmit = styled.button`
  display: flex;
  height: 50px;
  border: 2px solid #black;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background-color: #ff9e16;
  width: 100%;
  border-radius: 8px;
  color: #001e60;
  align-items: center;
  font-size: 1.15rem;
  font-weight: 500;
  justify-content: center;
  text-decoration: none;
  margin-top: 10px;
`

const Marker = styled.img`
  height: 50px;
  width: 29px;
  z-index: 999;
`

const debounce = (delay, fn) => {
  let timeout

  return function(...args) {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      fn(...args)
      timeout = null
    }, delay)
  }
}

export default class Map extends Component {
  state = {
    center: {
      lat: 0.0,
      lng: 0.0,
    },
    zoom: 16,
    address: 'Identifying Vehicle Location...',
    gpsAllowed: true,
    addressComponents: null,
    calculatedWidth: false,
  }

  static propTypes = {
    uuid: PropTypes.string.isRequired,
  }

  componentDidMount() {
    this.setPhysicalLocation()
  }

  geocodeLatitudeLongitude = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder
        .geocode({location: {lat: parseFloat(lat), lng: parseFloat(lng)}}, (results, status) => {
          if (status === "OK") {
            const filteredResults = getResultByTypeAndDistanceToCoords({lat, lng}, results);
            const reqObj = formatAddress(filteredResults)
            this.setState({
              address: filteredResults.formatted_address,
              addressComponents: reqObj,
            })
          }
        });
  }

  setPhysicalLocation = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        coords &&
          this.setState({
            center: { lat: coords.latitude, lng: coords.longitude },
            gpsAllowed: true,
          })
      },
      error => {
        console.warn(
          `Warning - ${
            error.message
          } GPS permissions not enabled. Please enable location for your browser.`
        )
        registerEvent({
          category: 'User',
          action: 'Location Services Disabled',
          label: this.props.uuid.substring(1),
        })
        this.setState({
          gpsAllowed: false,
        })
      }
    )
  }

  // This progammatically sets width for Google Maps container
  setWidth = () => {
    this.setState({
      calculatedWidth: !this.state.calculatedWidth,
    })
  }

  submitUserLocation = async addressObject => {
    console.log(
      'Location submitted: %s, UUID: %s',
      JSON.stringify({
        id: this.props.uuid.substring(1),
        addressComponents: addressObject,
      }),
      this.props.uuid.substring(1)
    )
    const result = await axios.post(getLocationEndpoint(), {
      registration: this.props.uuid.substring(1),
      ...addressObject,
    })
    console.log(result.status)
    registerEvent({
      category: 'User',
      action: 'Location Submitted',
      label: this.props.uuid.substring(1),
    })
  }

  onMapChange = async event => {
    if (event.center.lat === 0 && event.center.lng === 0) {
      return
    }

    this.setState({
      address: 'Identifying Vehicle Location...',
    })

    this.geocodeLatitudeLongitude(event.center.lat, event.center.lng)
  }

  debouncedChange = debounce(500, e => this.onMapChange(e))

  render() {
    const {
      zoom,
      center,
      address,
      gpsAllowed,
      calculatedWidth,
      addressComponents,
    } = this.state
    return (
      <MapWrapper calculatedWidth={calculatedWidth}>
        <GoogleMap>
          <Overlay>
            <Marker src={markerIcon} alt="This is your location" />
          </Overlay>

          <GoogleMapReact
            bootstrapURLKeys={{ key: `${process.env.GATSBY_MAP_API_KEY}`, libraries: ['geometry']}}
            resetBoundsOnResize={true}
            center={center}
            zoom={zoom}
            draggable={true}
            onChange={e => this.debouncedChange(e)}
            options={createMapOptions}
            onGoogleApiLoaded={() => this.setWidth()}
            yesIWantToUseGoogleMapApiInternals={true}
          />
          {/* <AddressWrapper>
            
          </AddressWrapper> */}
          <ButtonWrapper>
          <AddressBox
              placeholder="Placeholder from map.js"
              text={
                gpsAllowed
                  ? address
                  : 'Please Enable Location Services For Your Browser'
              }
              alertColor={gpsAllowed}
            />
            {!gpsAllowed || address === 'Identifying Vehicle Location...' ? (
              <DisabledSubmit>My Vehicle Is Here</DisabledSubmit>
            ) : (
              <Submit
                onClick={() => this.submitUserLocation(addressComponents)}
                to={`/success/?${this.props.uuid.substring(1)}`}
                state={{ address }}
                ref={el => {
                  this.myLink = el
                }}>
                My Vehicle Is Here
              </Submit>
            )}
          </ButtonWrapper>
        </GoogleMap>
      </MapWrapper>
    )
  }
}
