const isBrowser = typeof window !== 'undefined' ? true : false

export const getLocationEndpoint = () => {
  if (isBrowser) {
    if (window.location.href.includes('localhost')) {
      return 'http://localhost:8080/v1/lighthouse/locations'
    }
    if (window.location.href.includes('-dev')) {
      return 'https://lighthouse-dev.apps.csts-k8cnp-gl-1.r-caas.allstate.com/v1/lighthouse/locations'
    }
    if (window.location.href.includes('-uat')) {
      return 'https://lighthouse-uat.apps.csts-k8cnp-gl-1.r-caas.allstate.com/v1/lighthouse/locations'
    }
    if (window.location.href.includes('-preprod')) {
      return 'https://lighthouse-service-preprod.apps.csts-k8cpp-1.r-caas.allstate.com/v1/lighthouse/locations'
    }
    return 'https://lighthouse.allstate.com/v1/lighthouse/locations'
  }
}
